const zh = {
  ramsprogress: "RAMS进度",
  ramsaddress: "RAMS地址",
  ramsranking: "RAMS排行",
  ramranking: "RAM排行",
  test: "你好",
  language: {
    zh: "中文",
    en: "English",
  },
  searchUserName: "查询账户",
};
export default zh;
