const en = {
  ramsprogress: "progress",
  ramsaddress: "address",
  ramsranking: "RAMS Ranking",
  ramranking: "RAM Ranking",
  admin: "admin",
  test: "hello",
  language: {
    zh: "中文",
    en: "English",
  },
  searchUserName: "Search account",
};
export default en;
